import React from 'react';
import '../../../assets/styles/OurStory.css';
import ourStoryImage from '../../../assets/images/aboutus/story.png';

const OurStory = () => {
    return (
      <div className="our-story-section">
        <div className="our-story-image-container">
          <img
            src={ourStoryImage}
            alt="Illustration of Our Story"
            className="our-story-image"
          />
        </div>
        <div className="our-story-content">
          <h2 className="our-story-title">Our <span>Story </span></h2>
          <p className="our-story-description">
            Farmsell began with a simple belief: that agriculture should be as connected and efficient as any other industry. 
            From humble beginnings, we've grown into a thriving digital hub where farmers, suppliers, and buyers come together to 
            transform the way the world feeds itself.  
            <br />
            With every transaction, every handshake, and every harvest, we’re driven by a vision to empower agricultural communities, 
            promote sustainable practices, and create a future where every stakeholder thrives.
          </p>
          {/* <div className="our-story-buttons">
            <button className="learn-more-btn">Learn More</button>
            <button className="join-us-btn">Join Our Mission</button>
          </div> */}
        </div>
      </div>
    );
  };

export default OurStory;
