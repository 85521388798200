// // App.js
// import {BrowserRouter, Routes, Route} from 'react-router-dom';
// import Home from './components/home';
// import AboutUs from './components/AboutUs/index';
// import JoinFarmsellPage from './components/JoinFarmsell/page';
// import Registration from './components/Registration/Registration';
// import WelcomePage from './components/Registration/Welcome';
// import {GoogleOAuthProvider} from '@react-oauth/google';
// import TermsOfUser from './components/FarmsellLegal/TermsOfUser';
// import PrivacyPolicy from './components/FarmsellLegal/PrivacyPolicy';
// import {CommunityHome} from './components/CommunityPage/home';
// import HowItWorks from './components/HowItWorks/howitworks';
// import ContactUs from './components/ContactUs';
// import WelcomeComponent from './components/WelcomeComponent/index'
// import BlogPage from './components/BlogPages/BlogPage';
// import PageNotFound from './components/PageNotFound/index';

// function App () {
//   return (
//     <GoogleOAuthProvider clientId="1056600415170-9cn2lo9gm3hftp21eono93i9sij75eg3.apps.googleusercontent.com">
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/how-we-work" element={<HowItWorks />} />
//           <Route path="/about-us" element={<AboutUs />} />
//           <Route path="/contact-us" element={<ContactUs />} />
//           <Route path="/welcome/:name" element={<WelcomeComponent />} />
//           <Route path="/blog-page" element={<BlogPage />} />
//           <Route path="/join-farmsell" element={<JoinFarmsellPage />} />
//           <Route path="/register/:name" element={<Registration />} />
//           <Route path="/welcome" element={<WelcomePage />} />
//           <Route path="/terms" element={<TermsOfUser />} />
//           <Route path="/privacy" element={<PrivacyPolicy />} />
//           <Route path="/community" element={<CommunityHome />} />
//           <Route path="*" element={<PageNotFound />} /> {/* Catch-all route */}

//           {/* <Route path="/reg" element={<div>test</div>} /> */}

//         </Routes>
//       </BrowserRouter>
//     </GoogleOAuthProvider>
//   );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from './components/home';
import AboutUs from './components/AboutUs';
// import OrderForm from "./components/PlaceOrders/OrderForm";
import JoinFarmsellPage from './components/JoinFarmsell/page';
import Registration from './components/Registration/Registration';
import WelcomePage from './components/Registration/Welcome';
import TermsOfUser from './components/FarmsellLegal/TermsOfUser';
import PrivacyPolicy from './components/FarmsellLegal/PrivacyPolicy';
import { CommunityHome } from './components/CommunityPage/home';
import HowItWorks from './components/HowItWorks/howitworks';
import ContactUs from './components/ContactUs';
import WelcomeComponent from './components/WelcomeComponent/index';
import BlogPage from './components/BlogPages/BlogPage';
import PageNotFound from './components/PageNotFound/index';
import MetaTags from './components/MetaTags';
import SellerAgreement from './components/SellerAgreement';

import ImagePopup from './components/ImagePopup'; // Assuming this is the correct import path for your ImagePopup component

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    // Set isPopupOpen to true when the component mounts

    // Close the popup after 3 seconds
    const timer = setTimeout(() => {

      setIsPopupOpen(true);
    }, 10000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <GoogleOAuthProvider clientId="1056600415170-9cn2lo9gm3hftp21eono93i9sij75eg3.apps.googleusercontent.com">
      <MetaTags />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/how-we-work" element={<HowItWorks />} />
          <Route path="/about-us" element={<AboutUs />} />
          {/* <Route path="/order-form" element={<OrderForm />} /> */}
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/welcome/:name" element={<WelcomeComponent />} />
          <Route path="/BlogPage" element={<BlogPage />} />
          <Route path="/join-farmsell" element={<JoinFarmsellPage />} />
          <Route path="/register/:name" element={<Registration />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/terms" element={<TermsOfUser />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/community" element={<CommunityHome />} />
          <Route path="/seller-agreements" element={<SellerAgreement />} />
          <Route path="*" element={<PageNotFound />} /> {/* Catch-all route */}
        </Routes>
        {isPopupOpen && <ImagePopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />}
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
