import React from 'react';
import '../../../assets/styles/about-us/Leadership.css';
import leadershipImage from '../../../assets/images/aboutus/leadership.png';


const Leadership = () => {
    return (
      <div className="leadership">
        <div className="leadership-container">
          <div className="image-container">
            <img src={leadershipImage} alt="Leadership" />
          </div>
          <div className="text-container">
            <h2>Our <span>Leadership</span></h2>
            <p>
              At Farmsell, we are led by a team of passionate individuals who are committed to bringing innovation to the agricultural sector. Our leadership team is dedicated to ensuring that every step of our journey reflects our values of integrity, sustainability, and empowerment. Together, we are shaping the future of agriculture.
            </p>
          </div>
        </div>
      </div>
    );
  };
  
  export default Leadership;
