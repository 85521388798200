import React, { useState, useEffect } from "react";
import "../../../assets/styles/about-us/LifeAtFarmsell.css";
import image1 from "../../../assets/images/aboutus/life.png";
import image2 from "../../../assets/images/aboutus/life2.png";
import image3 from "../../../assets/images/aboutus/life3.png";
import image4 from "../../../assets/images/aboutus/life4.png";
import image5 from "../../../assets/images/aboutus/life2.png";

const LifeAtFarmsell = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [image1, image2, image3, image4, image5];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 3000); // Auto-slide every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  const handleAppRedirect = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=com.farmsell';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to Apple App Store
      window.location.href = 'https://apps.apple.com/ug/app/farmsell/id6447581635'; 
    } else {
      // Fallback (e.g., for Windows or other platforms)
      alert('The app is available on Android and iOS only. please use your phone to open it');
    }
  };

  return (
    <section className="life-at-farmsell">
      <div className="life-container">
        <div className="text-section">
          <h2 className="section-title">
            Life at <span className="highlight">Farmsell</span>
          </h2>
          <p className="section-subtitle">
            Join a thriving community where innovation meets collaboration. Explore the vibrant culture driving our mission forward.
          </p>
          <button className="cta-button" onClick={handleAppRedirect}>
            Get our App
          </button>
        </div>

        {/* Image Carousel */}
        <div className="image-section">
          <div className="carousel">
            {images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Life at Farmsell ${index + 1}`}
                className={`carousel-image ${
                  index === currentImage ? "active" : "inactive"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LifeAtFarmsell;
