import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/ImagePopup/ImagePopup.css";
import mainImage from "../../assets/images/popimage.png";

const ImagePopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const popupAlreadyShown = sessionStorage.getItem("popupShown");

    if (!popupAlreadyShown) {
      const inactivityTimer = setTimeout(() => setShowPopup(true), 10000); // Show popup after 10 seconds of inactivity
      return () => clearTimeout(inactivityTimer); // Clear the timer when the component unmounts
    }
  }, []);

  // Close popup and mark as shown
  const closePopup = () => {
    setShowPopup(false);
    sessionStorage.setItem("popupShown", "true");
  };

  // Handle "Join Now" button click
  const handleJoinClick = () => {
    setShowPopup(false);
    sessionStorage.setItem("popupShown", "true");
    navigate("/join-farmsell"); // Redirect to the join page
  };

  return showPopup ? (
    <div
      className="popup-overlay"
      onClick={(e) => e.target.className === "popup-overlay" && closePopup()}
    >
      <div className="popup-container">
        <button className="close-btn" onClick={closePopup}>
          &times;
        </button>
        <div className="popup-text">
          <h2 className="popup-title">Join Farmsell Today</h2>
          <p className="popup-para">Unleash the potential of your agricultural transactions!</p>
          <button className="cta-button" onClick={handleJoinClick}>
            Join Now, It’s Free
          </button>
          <p className="legal-popup">
            By continuing, you agree to Farmsell’s{" "}
            <a
              href="https://farmsell.org/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="popup-link"
            >
              Terms of Use
            </a>
            . Read our{" "}
            <a
              href="https://farmsell.org/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="popup-link"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <div className="popup-image">
          <img src={mainImage} alt="Farmsell Promotion" />
        </div>
      </div>
    </div>
  ) : null;
};

export default ImagePopup;
