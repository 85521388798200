import React, { useRef, useState, useEffect } from "react";
import "../../../assets/styles/about-us/Timeline.css";

const milestones = [
  {
    year: 2021,
    title: "Conception and Core Team",
    content:
      "Farmsell was founded to bridge gaps in agricultural supply chains. A core team was formed to redefine agricultural commerce in Africa.",
    dotColor: "#FF5733",
    titleBgColor: "#FFEBCC",
    titleTextColor: "#FF5733",
  },
  {
    year: 2022,
    title: "Business Model and Pilot Testing",
    content:
      "Refined the B2B model, developed the MVP, and conducted pilot testing to align the platform with market needs.",
    dotColor: "#33C1FF",
    titleBgColor: "#CCEFFF",
    titleTextColor: "#33C1FF",
  },
  {
    year: 2023,
    title: "Redevelopment with B2B Focus",
    content:
      "Enhanced features for bulk buyers and increased adoption through training and targeted campaigns.",
    dotColor: "#8D33FF",
    titleBgColor: "#E9CCFF",
    titleTextColor: "#8D33FF",
  },
  {
    year: 2024,
    title: "Tech Upgrades and Partnerships",
    content:
      "Introduced AI for demand forecasting and launched separate buyer/seller apps. Strategic partnerships expanded reach.",
    dotColor: "#33FF57",
    titleBgColor: "#CCFFCC",
    titleTextColor: "#33FF57",
  },
  {
    year: 2025,
    title: "Scaling Up",
    content:
      "Expanded to new markets and integrated blockchain for transparency. Farmsell became a leader in B2B agricultural trade.",
    dotColor: "#FFC733",
    titleBgColor: "#FFF5CC",
    titleTextColor: "#FFC733",
  },
];

const Timeline = () => {
  const timelineRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const timeline = timelineRef.current;

    // Set up infinite scrolling
    if (timeline) {
      const scrollWidth = timeline.scrollWidth / 2;
      const checkScroll = () => {
        if (timeline.scrollLeft >= scrollWidth) {
          timeline.scrollLeft = timeline.scrollLeft - scrollWidth;
        } else if (timeline.scrollLeft === 0) {
          timeline.scrollLeft = scrollWidth;
        }
      };
      timeline.addEventListener("scroll", checkScroll);
      return () => timeline.removeEventListener("scroll", checkScroll);
    }
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - timelineRef.current.offsetLeft);
    setScrollLeft(timelineRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - timelineRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust drag speed
    timelineRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  return (
    <div className="timeline-container">
      <div
        className="timeline-scroll"
        ref={timelineRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {[...milestones, ...milestones].map((milestone, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-year">{milestone.year}</div>
            <div
              className="timeline-dot"
              style={{ backgroundColor: milestone.dotColor }}
            ></div>
            <div className="timeline-content">
              <h3
                className="timeline-title"
                style={{
                  backgroundColor: milestone.titleBgColor,
                  color: milestone.titleTextColor,
                }}
              >
                {milestone.title}
              </h3>
              <p className="timeline-description">{milestone.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
