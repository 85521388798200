import React from 'react';
import '../../assets/styles/downloadCTA.css';
import AppImage from '../../assets/images/bannerNew.png';

const DownloadCTA = () => {
  const handleAppRedirect = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.farmsell";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/ug/app/farmsell/id6447581635";
    } else {
      alert("App is only available on Android and iOS platforms.");
    }
  };

  return (
    <div className="cta-banner">
      <div className="cta-content">
        <h2 className="cta-title">Download the <span>Farmsell</span> App Today!</h2>
        <p className="cta-description">
          Experience the ease of buying and selling agricultural products at your fingertips. 
          Join thousands of users transforming the agribusiness landscape with Farmsell.
        </p>
        <button className="cta-button" onClick={handleAppRedirect}>
          Get our App Now
        </button>
      </div>
      <div className="cta-image">
        <img src={AppImage} alt="Farmsell App Preview" />
      </div>
    </div>
  );
};

export default DownloadCTA;
