import React from "react";
import "../../../assets/styles/about-us/banner.css";



const BannerSection = () => {
    return (
        <div className="banner-section">
          <div className="banner-content">
            <h2 className="banner-statement">
              Farmsell is your trusted partner in revolutionizing agricultural trade, connecting farmers, suppliers, and buyers for a smarter, more sustainable future.
            </h2>
            <div className="stats-container">
              <div className="stat-item">
                <h3 className="stat-value">1M+</h3>
                <p className="stat-label">Active Users</p>
              </div>
              <div className="stat-item">
                <h3 className="stat-value">500K+</h3>
                <p className="stat-label">Products Listed</p>
              </div>
              <div className="stat-item">
                <h3 className="stat-value">300K+</h3>
                <p className="stat-label">Products Sold</p>
              </div>
              <div className="stat-item">
                <h3 className="stat-value">30+</h3>
                <p className="stat-label">Countries</p>
              </div>
            </div>
          </div>
        </div>
      );
    };
  
  export default BannerSection;