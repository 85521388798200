import React, { useState, useEffect } from 'react';
import '../../../assets/styles/tailoredbenefits.css';
import ArrowUp from '../../../assets/images/arrowup.svg';
import ArrowDown from '../../../assets/images/arrowdown.svg';
import SmallHolder from '../../../assets/images/tailored/smallholdFarmer.png';
import AgroBased from '../../../assets/images/tailored/industries.png';
import HotelandRestorant from '../../../assets/images/tailored/hotelRest.png';
import Supermarkets from '../../../assets/images/tailored/super.png';
import AgroInputs from '../../../assets/images/tailored/agroinput.png';



const FundingPriciple = () => {
  const [openSection, setOpenSection] = useState(0);

  // Function to toggle sections
  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  // Auto-switching logic
  useEffect(() => {
    const interval = setInterval(() => {
      setOpenSection((prevSection) =>
        prevSection < sections.length - 1 ? prevSection + 1 : 0
      );
    }, 10000); // Auto-switch every 10 seconds
    return () => clearInterval(interval);
  }, []);

  const sections = [
    {
      title: 'Empowerment Through Connectivity',
      content:
        "Farmsell's commitment extends beyond transactions to empowerment. By fostering seamless connectivity, we bridge the gaps between farmers, agri-input enterprises, institutions, and industries. Our platform acts as the catalyst for a united ecosystem where mutual benefits flourish.",
      img: SmallHolder,  
    },
    {
      title: 'Innovation in Agriculture',
      content:
        'Empowering Your Harvest, Empowering Your Life. Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs. Bid farewell to exploitation and market uncertainties as Farmsell becomes your reliable companion, ensuring your produce reaches the right buyers and your farm is equipped with the best tools for success.',
      img: AgroInputs,
    },
    {
      title: 'Transparency and Fair Trade',
      content:
        'Empowering Your Harvest, Empowering Your Life. Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs. Bid farewell to exploitation and market uncertainties as Farmsell becomes your reliable companion, ensuring your produce reaches the right buyers and your farm is equipped with the best tools for success.',
      img: HotelandRestorant,
    },
    {
      title: 'Elimination of Middlemen',
      content:
        'Empowering Your Harvest, Empowering Your Life. Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs. Bid farewell to exploitation and market uncertainties as Farmsell becomes your reliable companion, ensuring your produce reaches the right buyers and your farm is equipped with the best tools for success.',
      img: Supermarkets,
    },
    {
      title: 'Agro-Based Industries',
      content:
        'Efficiency with Direct Access to Quality Raw Materials. The Agro-Based industries, your efficiency journey begins with Farmsell. Gain direct access to high-quality raw materials sourced directly from farmers.',
      img: AgroBased,
    },
  ];

  return (
    <div className="tailored-benefits">
      <div className="header">
        <h1>
          Sowing <span className="colored">seeds</span> of{' '}
          <span className="colored">Transformation</span>
        </h1>
        <div className="desc-text">
        We prioritize innovation, transparency, fair trade, and eliminating 
        intermediaries to create a unified, sustainable agricultural ecosystem.
        </div>
      </div>

      <div className="content">
        {/* Accordion List */}
        <div>
          {sections.map((section, index) => (
            <div
              key={index}
              className={`element ${openSection === index ? 'open' : ''}`}
            >
              <div
                className="accordion-toggle"
                onClick={() => toggleSection(index)}
                aria-expanded={openSection === index}
              >
                <div className="progress-bar" aria-hidden="true"></div>
                {section.title}
                {openSection === index ? (
                  <img className="icon" src={ArrowUp} alt="arrow up" />
                ) : (
                  <img className="icon" src={ArrowDown} alt="arrow down" />
                )}
              </div>
              <div
                className={`accordion-content ${
                  openSection === index ? 'show' : ''
                }`}
              >
                <div>
                  <p>{section.content}</p>
                  <div className="phone-image">
                    <img
                      src={section.img}
                      alt={`Visual aid for ${section.title}`}
                      className={`accordion-image ${
                        openSection === index ? 'show' : 'hide'
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop Image */}
        <div className="destop-image">
          <img
            src={sections[openSection].img}
            alt={`Visual aid for ${sections[openSection].title}`}
            className="fade-in"
          />
        </div>
      </div>
    </div>
  );
};

export default FundingPriciple;