import React, { useEffect } from 'react';
import Footer from './Footer';
import WhyFarmSell from './WhyFarmsell/WhyFarmsell';
import HeroSection from './Revolutionizing/HeroSection';
import BannerGetStarted from './GetStarted/BannerGetStarted';
// import Header from './Header';
import OurServices from './OurServices/ourServices';
import TailoredBenefits from './TailoredBenefits/tailoredBenefits';
import HowItWorks from './HowFarmsellWorks/HowItWorks';
import JoinFarmsell from './JoinFarmsell/joinFarmsell';
import TrustedClient from './TrustedClient/trustedClient';
import WelcomeToFarmsell from './WelcomeFarmsell/welcomeToFarmsell';
import { Element } from 'react-scroll';
// import BurgerMenu from '../components/Header/Burger/BurgerMenu';
import Header from './Header';
import TestimonialPage from './Testimonies';
import navigateToTopPage from '../shared/navigateTop';
import { Helmet } from 'react-helmet';
import DownloadCTA from './DownloadCTA/DownloadCTA';

const Home = () => {
  useEffect(() => {
    // Use this to scroll to the top when the component mounts
    window.scrollTo(0, 0);  // Ensures scroll position is at the top
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Helmet>
        <title>Farmsell: home </title>
        <meta name="description" content="Transform your agribusiness with Farmsell - the pioneering B2B marketplace revolutionizing
         agricultural trade across Africa and beyond. Seamlessly connect with reliable markets, eliminate middlemen hassles,
          and boost income. Leveraging smart-sourcing powered by AI, Farmsell is your exclusive gateway to efficient bulk 
          transactions. From smallholders to the hospitality industry and Agro based industries, discover a holistic solution 
          dedicated to agriculture. Join Farmsell now for hassle-free selling and buying of agricultural products."/>
      </Helmet>

      <Header />
      <main>
        <HeroSection />
        <TrustedClient />
        <Element id="welcome-to-farmsell">
          <WelcomeToFarmsell />
        </Element>

        <WhyFarmSell />
        <Element id="service">
          <OurServices />
        </Element>
        <TailoredBenefits />
        {/* <BannerGetStarted /> */}
        
        <DownloadCTA />
        <HowItWorks />
        <TestimonialPage />
        <JoinFarmsell />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
