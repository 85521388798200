import React from "react";
import "../../../assets/styles/about-us/MissionVision.css";
import missionImage from "../../../assets/images/aboutus/mission.png";
import visionImage from "../../../assets/images/aboutus/vision.png";

const MissionVision = () => {
  return (
    <section className="missions-visions">
      <div className="mission-vision-container">
        <div className="text-section">
          <h2 className="section-title">
            Our <span className="missionSpan">Mission</span> and{" "}
            <span className="missionSpan">Vision</span>
          </h2>
          <p className="section-desc">
          Discover our vision and mission for advancing agriculture through prosperity, sustainability, and innovation
          </p>
        </div>

        <div className="vision-mission-cards">
          <div className="card">
            <img src={missionImage} alt="Mission" className="card-image" />
            <h3 className="card-title">Our Mission</h3>
            <p className="card-content">
            We aim to transform agricultural transactions by empowering farmers and businesses, driving sustainable growth,
            and fostering a tech-driven ecosystem for greater efficiency and profitability.
            </p>
          </div>

          <div className="card">
            <img src={visionImage} alt="Vision" className="card-image" />
            <h3 className="card-title">Our Vision</h3>
            <p className="card-content">
            We envision an agricultural landscape that thrives on innovation, sustainability, and improved profitability for
            all participants, transforming the B2B marketplace for the better.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
