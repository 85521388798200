import React, { useState } from 'react';
import '../../../assets/styles/about-us/Testimonials.css';
import Image1 from '../../../assets/images/aboutus/tests1.png'; 
import Image2 from '../../../assets/images/aboutus/tests2.png';
import Image3 from '../../../assets/images/aboutus/tests3.png';

const Testimonials = () => {
    const [currentTestimony, setCurrentTestimony] = useState(0);
  
    const testimonies = [
      {
        name: 'John Doe',
        title: 'CEO, AgriTech Inc.',
        company: 'AgriTech Inc.',
        text: 'Farmsell has revolutionized how we source agricultural products. Their seamless platform and excellent support make them a leader in the industry.',
        image: Image1,
      },
      {
        name: 'Jane Smith',
        title: 'Operations Manager, GreenGrow',
        company: 'GreenGrow',
        text: 'Thanks to Farmsell, we’ve seen improved efficiency in our supply chain. Their commitment to sustainability is unmatched.',
        image: Image2,
      },
      {
        name: 'Mark Johnson',
        title: 'Founder, AgriConnect',
        company: 'AgriConnect',
        text: 'Partnering with Farmsell has been a game-changer. Their innovative approach to B2B transactions is exactly what the industry needed.',
        image: Image3,
      },
    ];
  
    const handleNext = () => {
      setCurrentTestimony((currentTestimony + 1) % testimonies.length);
    };
  
    const handlePrev = () => {
      setCurrentTestimony(
        (currentTestimony - 1 + testimonies.length) % testimonies.length
      );
    };
  
    return (
      <div className="testimonials">
        <div className="header">
          <h2>
           Our Impact in Their<span className="colored"> Words</span>
          </h2>
        </div>
  
        <div className="testimonial-container">
          <div className="text-content">
            <p className="testimonial-text">"{testimonies[currentTestimony].text}"</p>
            <h3 className="testimonial-name">{testimonies[currentTestimony].name}</h3>
            <p className="testimonial-title">
              {testimonies[currentTestimony].title}, {testimonies[currentTestimony].company}
            </p>
          </div>
  
          <div className="image-content">
            <img
              src={testimonies[currentTestimony].image}
              alt={`${testimonies[currentTestimony].name}`}
            />
          </div>
        </div>
  
        <div className="navigation">
          <button className="arrow" onClick={handlePrev}>
            &#8249;
          </button>
          <button className="arrow" onClick={handleNext}>
            &#8250;
          </button>
        </div>
      </div>
    );
  };
  
  export default Testimonials;
