import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll"; 
import "../../assets/styles/heroSection.css";
import HeroImage from "../../assets/images/Hero/hero3.png";
import GradientText from "./GradientText";

const HeroSection = () => {
  const originalText = "Connect with Farmers";
  const replacementTexts = [
    { text: "Hotels", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Supermarkets", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Agro-Input Enterprises", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Agro-Based Industries", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Smallholder Farmers", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
  ];
  const [modifiedText, setModifiedText] = useState(originalText);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomReplacement = replacementTexts[Math.floor(Math.random() * replacementTexts.length)];
      setModifiedText(randomReplacement);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [replacementTexts]);

  const handleAppRedirect = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.farmsell";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/ug/app/farmsell/id6447581635";
    } else {
      alert("App is only available on Android and iOS platforms.");
    }
  };

  return (
    <div className="hero-section">
      <div className="hero-content">
        {/* Text Section */}
        <div className="hero-texts">
          <h1 className="hero-h">
            Transform Farming with <span className="hero-span">Farmsell.</span> Your Trusted{" "}
            <span className="hero-span">B2B Marketplace</span>
          </h1>
          <p className="hero-p">
            Connect with <GradientText text={modifiedText.text} gradient={modifiedText.gradient} /> to maximize your profitability
          </p>
        </div>

        {/* Buttons */}
        <div className="hero-buttons">
          <ScrollLink
            to="welcome-to-farmsell" 
            smooth={true}
            duration={900} 
            className="hero-button primary"
          >
            Explore More
          </ScrollLink>
          <button className="hero-button" onClick={handleAppRedirect}>
            Get our App
          </button>
        </div>

        {/* Image */}
        <div className="hero-image">
          <img className="hero-images" src={HeroImage} alt="Hero" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
