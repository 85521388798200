import React from 'react';
import '../../assets/styles/HeroSection1.css';

const HeroSection = () => {
  const handleDownload = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=com.farmsell';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to Apple App Store
      window.location.href = 'https://apps.apple.com/ug/app/farmsell/id6447581635'; // Replace with your actual App Store link
    } else {
      // Fallback (e.g., for Windows or other platforms)
      alert('The app is available on Android and iOS only. Please open this on your phone');
    }
  };

  return (
    <div className="container-HeroSection">
      <div className="moving-shapes left-shape"></div>
      <div className="moving-shapes right-shape"></div>
      <div className="textContainerhere">
        <h2 className="Cultivaed">We are Farmsell</h2>
        <h3 className="Premier">
          Where <span className="Prem">Growth</span> Meets{' '}
          <span className="B2B">Opportunity!</span>
        </h3>
        <p className="Reward">
          From the soil to the marketplace, we're here to cultivate your success.
          Whether you're planting seeds of innovation or harvesting the rewards of your hard work,
          Farmsell connects you to a world of endless agricultural possibilities.
        </p>
        <div className="buttonContainer">
          <button className="btn download-app-btn" onClick={handleDownload}>
            Download the App
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
