import React, { useEffect } from 'react';
import Header from '../Header/index';
import Footer from '../Footer';
import HeroSection from '../HeroSection/index';
import MeetTheVisionary from '../MeetTheVisionary/index';
import FundingPriciple from './FundingPriciple';
import CoreValues from './CoreValues';
import OurAchivements from './OurAchivements';
import StrategicComponent from './StrategicComponent';
import OurApproach from './OurApproach';
import ElevateYour from './ElavetYour';
import TestimonialPage from '../Testimonies';
import JoinFarmsell from '../JoinFarmsell/joinFarmsell';
import { Helmet } from 'react-helmet';
import OurStory from './OurStory';
import BannerSection from './Banner';
import MissionVision from './MissionVission';
import Timeline from './Timeline';
import Leadership from './Leadership';
import Testimonials from './Testimonials';
import DownloadCTA from '../DownloadCTA/DownloadCTA';
import LifeAtFarmsell from './LifeAtFarmsell';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Farmsell: About Us</title>
        <meta name="description" content="Embark on a transformative journey with Farmsell, the leading B2B marketplace for 
         products. Immerse yourself in innovation, efficiency, and integrity as we connect farmers with buyers seamlessly. 
         Our exclusive focus on agriculture ensures tailored solutions and sustainable practices. Download Farmsell today for a 
         future of prosperity in every transaction."/>
      </Helmet>
      <main style={{ overflow: 'hidden' }}>
        <Header />
        <HeroSection />
        <BannerSection />
        <OurStory />
        <Timeline />
        <MissionVision />
        <DownloadCTA />
        {/* <MeetTheVisionary /> */}
        
        <FundingPriciple />
        {/* <ElevateYour /> */}
        <CoreValues />
        {/* <OurAchivements /> */}
        {/* <OurApproach /> */}
        {/* <StrategicComponent /> */}
        <LifeAtFarmsell />
        <Leadership />
        
        <Testimonials />
       
        {/* <TestimonialPage /> */}
        {/* <JoinFarmsell /> */}
        <Footer />
      </main>
    </div>
  );
};

export default AboutUs;
